.root{
    display: flex;
    font-family: var(--font-main), Sans-serif, Arial;
    flex-direction: column;
}
.settingsContainer{
    margin: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    flex-direction: column;
    padding: 0;
    display: flex;
}
.tabs{
    display: none !important;
}
.spanContainer{
    text-align: center;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .settingsContainer {
        background-color: white !important;
        border-radius: 0 !important;
        display: flex;
        flex-direction: row;
        box-shadow: unset !important;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .tabs{
        display: unset !important;
        min-height: 400px !important;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}