.cardStyle{
    border: 2px solid var(--primary-background-secondary);
    font-size: 1rem;
    position: relative;
    width: 330px;
    height: 260px;
    transition: all 1s !important;
    z-index: 1;
    flex: 0 0 calc(100% - 20px);
    margin: 20px 0;
}
.cardActionArea{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
    border-radius: 5px !important;
}
.cardTitle{
    font-family: var(--main-font), Sans-serif, Arial !important;
    font-weight: bold !important;
    letter-spacing: 0.1rem !important;
    line-height: 1.54 !important;
    text-align: center;
    display: block;
    padding: 2rem;
    color: var(--primary-background-secondary);
    height: 1.8rem;
}
.dialogContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.dialogContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title1{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: var(--font-main), Sans-serif, Arial;
}
.title2{
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    margin: 1rem 1rem 0.5rem 1rem;
    text-transform: none;
    font-family: var(--font-main), Sans-serif, Arial;
    display: block;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
}
.downloadLink{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}
.downloadLink:hover {
    color: purple;
}
.buttonsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .cardStyle{
        flex: 0 0 calc(50% - 20px);
    }
    .dialogContainer{
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        max-width: 1000px;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .cardStyle{
        flex: 0 0 calc(33% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .cardStyle{
        flex: 0 0 calc(25% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .cardStyle{
        flex: 0 0 calc(16.66% - 20px);
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}