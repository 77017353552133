.infoCard {
  width: 100%;
  margin: 16px 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.cardTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.print_options_wrapper{
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section {
  margin-bottom: 24px;
  width: 100%;
}

.credentialsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
}

.credentialRow {
  background-color: rgb(231, 231, 231);
  display: flex;
  align-items: center;
  padding: 0.2rem;
  border-radius: 10px;
  gap: 8px;
}

.copyButton {
  margin-left: auto;
}

.button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  padding: 1rem;
  margin-top: 0.5rem;
  text-decoration: none;
  width: 220px;
  color: whitesmoke ;
  background-color: var(--primary-background-primary) ;
  text-transform: none;
  opacity: 1;
  transition: opacity 300ms;
}
.button:hover{
  cursor: pointer;
  opacity: 0.8;
  color: var(--primary-background-primary);
  color: whitesmoke;
}

@media (min-width: 768px) {  
  .button{
    align-self: flex-start;
  }
  .print_options_wrapper{
    flex-direction: row;
  }
  .section {
    width: 50%;
  }
}