.cardStyle{
    font-size: 1rem;
    position: relative;
    width: 100%;
    transition: all 1s;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
    flex: 0 0 calc(100% - 20px);
    margin: 20px 10px;
}
.selectContainer{
    position: relative;
    margin: 0 auto;
    margin-bottom: -6.25rem;
    z-index: 1000;
    display: flex;
    justify-content: center;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    overflow: hidden;
    height: 100px;
    width: 100px;
    background: #fefefe;
}
.groupSelect{
    margin-top: 0.7rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}
.switch{
    width: 42px;
    height: 26px;
    padding: 0px;
    top: 0.2rem;
}
.delete {
    position: absolute !important;
    top: 0.1rem !important;
    right: 0.5rem !important;
    z-index: 2 !important;
    &:hover {
        color: var(--primary-error) !important;
    }
}
.cardActionArea{
    border-radius: 5px !important;
    height: calc(100% - 38px);
}
.media{
    width: 100%;
    height: 200px;
    margin: 0 auto;
}
.cardContent{
    width: 100%;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.cardTitle{
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-weight: bold !important;
    letter-spacing: 0.1rem !important;
    line-height: 1.54 !important;
    text-align: center;
    display: block;
    color: var(--primary-background-secondary);
    height: 1.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.searchKeyContainer{
    display: flex;
    align-items: center;
}
.validThru{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
}
.validThruInner{
    display: flex;
    flex-direction: row;
    column-gap: 0.2rem;
    font-size: 0.8rem !important;
}
.countwrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.itemData{
    transition: opacity 1s linear;
    opacity: 0;
}
.customDialogTitle{
    font-size: 0.65rem;
    letter-spacing: 0.1rem;
    line-height: 1.54;
}
.dialogActions{
    margin: 0 auto;
    max-width: 100%;
    padding: 0 5rem 1rem 5rem;
}
.customButton{
    background-color: var(--primary-main);
    border-color: var(--primary-main);
    color: var(--primary-contrast-text);
    text-transform: uppercase;
    font-size: .7rem;
    letter-spacing: .1rem;
    line-height: 1.54;
    width: 100%;
    transition: 250ms ease-in;
}
.custom-button:hover, .custom-button:focus {
  background-color: var(--primary-contrast-text);
  color: var(--primary-background-secondary);
}
.checkbox{
    padding: 0 1rem 0 1rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .cardStyle {
        flex: 0 0 calc(50% - 20px);
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .cardStyle {
        flex: 0 0 calc(33% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .cardStyle {
        flex: 0 0 calc(25% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .cardStyle {
        flex: 0 0 calc(16.66% - 20px);
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}