
.button_wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.cancel_button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  width: 200px;
  color: whitesmoke;
  background-color: var(--primary-background-primary);
  text-transform: none;
  opacity: 1;
  border: none;
  transition: opacity 300ms;
}
.cancel_button:hover{
  cursor: pointer;
  opacity: 0.8;
  color: var(--primary-background-primary);
  color: whitesmoke;
}
.submit_button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  width: 200px;
  color: var(--primary-background-primary);
  background-color: var(--primary-background-secondary);
  text-transform: none;
  opacity: 1;
  border: none;
  transition: opacity 300ms;
}
.submit_button:hover{
  cursor: pointer;
  opacity: 0.8;
}
.disabled_button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  width: 200px;
  color: lightgrey;
  background-color: transparent;
  text-transform: none;
  opacity: 1;
  border: 1px solid grey;
  transition: opacity 300ms;
}
