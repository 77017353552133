.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}
.grid{
    width: 300px;
    align-self: center;
    padding: 0;
    margin: 0;
}
.formLabel{
    font-family: var(--font-main), Sans-serif, Arial;
}

.specialGridItem{
    margin-bottom: 1rem !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .grid{
        width: 600px;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}