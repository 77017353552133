.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 3px solid var(--primary-main);
    justify-content: space-between;
}
.title{
    font-family: var(--font-main), Sans-serif, Arial;
    font-weight: 600 !important;
    padding: 1rem !important;
}
.orderDetail{
    padding: 0 2rem 2rem 2rem;
    position: relative;
}
.selectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
}
.statusRow {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.formControl {
    min-width: 200px;
    flex: 1;
}

.inputLabel:global(.MuiFormLabel-root) {
    color: black !important;
}

.statusSelect:global(.MuiSelect-root.Mui-focused){
    border-color: black !important;
}
.statusSelect:global(.Mui-focused){
    border-color: black !important;
}


.checkButton {
    margin-top: 8px !important;
    background: rgb(233, 233, 233) !important;
    
    &:hover {
        background: rgb(211, 211, 211) !important;
    }
    &:disabled {
        background: rgb(233, 233, 233) !important;
    }
}