.chooserCard{
    padding: 1rem 0;
}
.chooserTitle{
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    margin-bottom: 16px;
}
.chooserWrapper{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
}
.chooserWrapper div{
    row-gap: 1rem;
    font-family: var(--font-main);
    width: 100%;
}
.selectedMethod{
    flex: 1;
    padding: 16px;
    border-radius: 8px;
    border: 2px solid #FFB800;
    background-color: #FFFBEB;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
}
.methodCard{
    flex: 1;
    padding: 16px;
    border-radius: 8px;
    border: 2px solid #E5E7EB;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
}
.methodCard:hover, .selectedMethod:hover{
    border-color: #ffb800;
}
.methodCardDisabled{
    flex: 1;
    padding: 16px;
    border-radius: 8px;
    border: 2px solid #E5E7EB;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
}
.methodContent{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.methodHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
}
.methodName{
    font-weight: 500;
    color: #111827;
    margin-bottom: 4px;
}
.methodDescription{
    font-size: 14px;
    color: #6b7280;
}
.checkIcon{
    color: #ffb800;
    font-size: 20px;
}
.price{
    font-weight: 500;
    color: #111827;
    padding-left: 2px;
}