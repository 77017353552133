.cardDataContainer{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
}
.topContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    column-gap: 1rem;
}
.cardWrapper{
    margin: 0 auto;
    width: 100%;
    min-width: 50%;
    display: flex;
    justify-content: center;
}
.creditCard{
    background-color: var(--primary-main);
    width: 100%;
    max-width: 380px;
    height: 190px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}
.chip{
    position: absolute;
    top: 0;
    left: 0;
}
.progressYellow{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.acceptContainer{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
}
.buttonWrapperDelete{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    margin: auto;
}
.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 55px;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
}
.buttonDark{
    border-radius: 30px !important;
    align-self: center;
    height: 50px;
    width: 200px;
    color: whitesmoke !important;
    background-color: var(--primary-background-primary) !important;
    text-transform: none !important;
    opacity: 1 !important;
    transition: opacity 300ms !important;
}
.buttonDark:hover{
    cursor: pointer;
    opacity: 0.8 !important;
    color: whitesmoke !important;
}
.buttonDark:disabled{
    cursor: unset;
    opacity: 0.8 !important;
    background: transparent !important;
    border: 1px solid grey !important;
    color: lightgray !important;
}
.checkboxRed{
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: red;
}
.checkboxRed span a {
    color: red;
}
.checkboxRed span svg {
    color: red;
}
.checkbox {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: var(--text-primary);
}
.checkbox span a {
    color: var(--text-primary);
}
.checkbox span svg {
    color: var(--text-primary);
}
.accordion_container{
    width: 100%;
}

.details{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 280px;
    overflow: auto;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .buttonWrapper{
        flex-direction: row;
    }
    .creditCard{height: 230px;}
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .topContainer{
        flex-direction: row;
    }
    .acceptContainer{
        width: 40%;
    }
    .cardWrapper{
        width: 60%;
        margin-left: 0.5rem;
        justify-content: flex-start;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .topContainer{
        flex-direction: row;
    }
    .buttonWrapper{
        flex-direction: row;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}


.container{
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
}
.iconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.successCheck{
    width: 52px;
    height: 52px;
}
.successCheckCircle{
    stroke: #4bb543;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.succcesCheckCheck{
    stroke: #4bb543;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
}
@keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
}
.cardTitle{
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin-top: 1rem;
    text-align: center;
}
.orderDetails{
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
}
.detailsTitle{
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 1.5rem;
}
.detailsMessage{
    font-size: 0.875rem;
    color: #4b5563;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}
.detailRow{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid #e5e7eb;
}
.reregister_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}
.detailRow:last-child{
    border-bottom: none;
}
.detailLabel{
    font-size: 0.875rem;
    color: #6b7280;
    width: 120px;
    flex-shrink: 0;
}
.detailValue{
    font-size: 0.875rem;
    color: #111827;
    font-weight: 500;
    flex: 1;
}
.copyButton{
    padding: 4px;
    min-width: unset;
    color: #6b7280;
    transition: color 0.3s, background-color 0.3s;
}
.copyButton:hover{
    color: #111827;
    background-color: #f3f4f6;
}
.homeButton{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    margin-top: 2rem;
    background-color: #ffb800;
    color: #111827;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s;
}
.homeButton:hover{
    background-color: #e5a600;
}

@media (max-width: 600px) {
    .container{
        padding: 0;
        padding-bottom: 2rem;
        max-width: 300px;
    }
    .detailRow{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .detailLabel{
        width: 100%;
    }
}