
.editContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 2rem;
    min-height: 300px;
}

.editHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    background-color: var(--primary-background-secondary);
    color: whitesmoke;
    padding: 0.3rem 2rem 0.3rem 1rem;
    border-radius: 0 15px 15px 0;
    margin-left: -1rem;
    max-width: 300px;
}

.buttonClose {
    padding: 0 !important;
    min-width: 0 !important;
}

.editMainContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
    height: 100%;
}

.leftEdit {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.rightEdit {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.buttonAdd {
    margin: 0 0 0 auto !important;
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .editMainContainer {
        flex-direction: row;
    }
    .leftEdit {
        width: 40%;
    }
    .rightEdit {
        width: 60%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}