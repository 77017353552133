.mainContainer {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    min-height: 750px;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    outline: none !important;
    p {
        color: var(--primary-background-secondary) !important;
    }    
}
.breadcrumb_link{
    font-family: var(--font-main), Sans-serif, Arial !important;
    border: none;
    background: transparent;
    font-size: 1rem;
    color: grey;
    padding: 0;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.switchContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
    justify-content: center;
  }
.cardContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    margin-bottom: 2rem;
    justify-content: center;
    box-sizing: border-box;
    min-height: 575px;
    max-width: 1300px;
    width: 100%;
}


/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .mainContainer {
        width: 100%;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .mainContainer {
        width: 100%;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}