.mainContainer {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    min-height: 750px;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
   /* 
    justify-content: center;
    align-items: center;
    padding: 2rem 0.5rem;
    width: 100%;
    max-width: 1400px;
    
    margin: 0 auto;
    font-family: var(--font-main); */
}

.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.middleContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    width: 100%;
}
.middleLeft {
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
}
.middleRight {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
}
.rightBottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.subscriptionInfoContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.subscriptionInfo {
    background-color: var(--primary-background-secondary);
    color: black;
    padding: 0.3rem 2rem 0.3rem 1rem;
    border-radius: 0 15px 15px 0;
    margin: auto 0 auto -1rem;
    max-width: 300px;
}

.title {
    text-align: center;
    margin-top: 0;
}
.logoContainer {
    width: 150px;
    margin: auto;
    margin-bottom: 0;
}
.bottom {
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}

.buttonDark{
    border-radius: 30px !important;
    align-self: center;
    height: 50px;
    width: 200px;
    color: whitesmoke !important;
    background-color: var(--primary-background-primary) !important;
    text-transform: none !important;
    opacity: 1 !important;
    transition: opacity 300ms !important;
}
.buttonDark:hover{
    cursor: pointer;
    opacity: 0.8 !important;
    color: var(--primary-background-primary) !important;
    color: whitesmoke !important;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 280px;
    overflow: auto;
}

.detail_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.bundles_title{
    font-size: medium;
}

.button_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  
  .cancel_button{
    border-radius: 30px;
    align-self: center;
    height: 50px;
    width: 200px;
    color: whitesmoke;
    background-color: var(--primary-background-primary);
    text-transform: none;
    opacity: 1;
    border: none;
    transition: opacity 300ms;
  }
  .cancel_button:hover{
    cursor: pointer;
    opacity: 0.8;
    color: var(--primary-background-primary);
    color: whitesmoke;
  }
  .submit_button{
    border-radius: 30px;
    align-self: center;
    height: 50px;
    width: 200px;
    color: var(--primary-background-primary);
    background-color: var(--primary-background-secondary);
    text-transform: none;
    opacity: 1;
    border: none;
    transition: opacity 300ms;
  }
  .submit_button:hover{
    cursor: pointer;
    opacity: 0.8;
  }

/* -- Mobile landscape */
@media (min-width: 480px) {
    .mainContainer{
        padding: 2rem;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .middleContainer {
        flex-direction: row;
        margin: 0 auto;
        width: 98%;
        max-width: 1700px; 
    }
    .middleLeft {
        width: 65%;
    }
    .middleRight {
        width: 35%;
    }
    .bottom{
        margin: 0 auto;
        width: 98%;
        max-width: 1700px; 
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    
    .bundles_title{
        font-size: 21px;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .mainContainer {
        width: 100%;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}