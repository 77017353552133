.tableContainer{
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-bottom: 2rem;
}
.header{
    background-color: var(--primary-background-secondary);
    color: white;
    table-layout: fixed;
    padding: 0.5rem 0;
    width: 100%;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.hideXs{
    display: none;
    font-family: var(--font-main);
}
.hideMd{
    display: none;
    font-family: var(--font-main); 
}
.hideLg{
    display: none;
    font-family: var(--font-main);
}
.heading{
    font-family: var(--font-main) !important;
    font-size: 0.75rem;
    font-weight: 600;
}
.tableCell{
    font-family: var(--font-main) !important;
}
.orderContainer{
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
    background-color: whitesmoke;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.listitemtable{
    width: 100%;
    table-layout: fixed;
    transition: background 300ms;
    border-radius: 0 0 4px 4px;
}
.listItem{
    height: 50px;
    border-radius: 0 0 4px 4px;
}
.listItem:hover{
    cursor: pointer;
    background: rgb(220, 220, 220);
}
/* -- Mobile landscape */
@media (min-width: 480px) {
    .hideXs{
        display: table-cell;
        overflow: hidden;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .hideMd{
        display: table-cell;
        overflow: hidden;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .hideLg{
        display: table-cell;
        overflow: hidden;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}