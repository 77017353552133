.root{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main, Arial), Sans-serif !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.content{
    margin-top: -3rem;
    margin-bottom: 3rem;
    flex-grow: 1;
    overflow: auto;
}
.newGroupContainer{
    width: 100%;
    padding-top: 3rem;
    display: grid;
    place-items: center;
}
.updateIndicator{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.groupsContainer{
    display: flex;
    flex-wrap: wrap;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .updateIndicator{
        position: absolute;
        margin-bottom: 0;
        bottom: 0.5rem;
        right: 0.5rem;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}