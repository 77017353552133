.name {
    width: 100%;
    color: var(--primary-text-primary);
    text-align: center;
    margin: 0.5rem 0 !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}