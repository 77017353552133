.mainPart {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 2rem;
    width: 98%;
    margin: 0 auto;
    padding-top: 1rem;
}

.overlayContainer {
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.5);
    backdrop-filter: blur(4px);
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.overlayText{
    color: black;
    font-size: 24px;
    padding: 2rem;
}
.content {
    position: relative;
    height: 100%;
}
.dialogMain{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
}
.button{
    border-radius: 30px;
    align-self: center;
    height: 50px;
    width: 200px;
    color: var(--primary-background-primary);
    background-color: var(--primary-background-secondary);
    text-transform: none;
    opacity: 1;
    border: none;
    transition: opacity 300ms;
  }
  .button:hover{
    cursor: pointer;
    opacity: 0.8;
  }
.buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 1rem;
    column-gap: 1rem;
}
.buttonDark {
    border-radius: 30px !important;
    padding: 0 4rem !important;
    margin: 0 auto !important;
    height: 50px;
    color: whitesmoke !important;
    background-color: var(--primary-background-primary) !important;
    text-transform: none !important;
    line-height: 0 !important;
}
.buttonDark:hover {
    cursor: pointer;
    color: var(--primary-background-cover) !important;
}
.buttonDark:disabled {
    background-color: gray !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .mainPart {
        flex-direction: row;
    }
    .overlayContainer {
        width: 50%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}