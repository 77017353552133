.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2rem;
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
}
.rightOverlay{
    width:100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2rem;
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
}

.rightUpper {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.h3 {
    margin: 0;
}

.title {
    background-color: var(--primary-background-secondary);
    color: black;
    padding: 0.3rem 2rem 0.3rem 1rem;
    border-radius: 0 15px 15px 0;
    margin-left: -1rem;
    max-width: 300px;
}

.templateContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
}

.templateBubble {
    background-color: var(--primary-background-primary);
    padding: 0.2rem 2rem;
    border-radius: 30px;
    color: whitesmoke;
}

.buttonDark {
    border-radius: 30px !important;
    padding: 0 4rem !important;
    margin: 0 auto !important;
    height: 50px;
    color: whitesmoke !important;
    background-color: var(--primary-background-primary) !important;
    text-transform: none !important;
    line-height: 0 !important;
}

.buttonDark:hover {
    cursor: pointer;
    color: var(--primary-background-cover) !important;
}
.buttonDark:disabled {
    background-color: gray !important;
}

  /* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .right {
        width: 50%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}